<template>
  <div class="app-container">
    <div class="upload">
      <van-uploader
        name="card"
        :accept="'image/*'"
        :after-read="onRead"
        v-model="fileList"
        :max-count="1"
      >
      </van-uploader>
    </div>

    <van-cell-group>
      <van-cell>
        <van-checkbox @change="changeCheckbox" v-model="checkbox" shape="square">海报底部</van-checkbox>
      </van-cell>
      <van-field
        v-if="form.has_bottom === 1"
        v-model="form.content"
        rows="1"
        autosize
        label="一句话"
        type="textarea"
        placeholder="你想对客户说点什么"
      />
    </van-cell-group>

    <div class="btn">
      <van-button type="info" block @click="onSubmit">生成海报H5</van-button>
    </div>
  </div>
</template>
<script>
import wx from 'weixin-js-sdk'
import { Notify } from 'vant'
export default {
  data () {
    return {
      fileList: [],
      checkbox: true,
      form: {
        cover_img: '',
        content: '',
        has_bottom: 1
      }
    }
  },
  mounted () {
    console.log(wx)
  },
  methods: {
    changeCheckbox(){
      console.log(this.checkbox)
      this.form.has_bottom = this.checkbox ? 1 : 2
    },
    onRead (file) {
      file.status = 'uploading'
      file.message = '上传中...'
      let formData = new FormData()
      formData.append('type', 'card')
      formData.append('file', file.file)
      this.$axios.post('/wxc/index/upload', formData).then(res => {
        console.log(res)

        if (res.code === 200) {
          // file.url = res.data.file_url
          this.form.cover_img = res.data.file_url
          file.status = 'done'
          file.message = '上传成功'
        } else {
          file.status = 'failed'
          file.message = '上传失败'
        }
      })
    },
    onSubmit () {
      this.$axios.post('/wxc/cardfeed/create', this.form).then(res => {
        if (res.code === 200) {
          this.$router.push({
            name: 'Kv',
            query: {
              id: res.data.sys_id
            }
          })
        }else{
          Notify({ type: 'danger', message: res.msg });
        }
      })
    }
  }
}
</script>
<style lang="scss" scoped>
.app-container {
  /deep/ .van-uploader__upload {
    width: 100vw;
    height: auto;
    min-height: 100vw;
  }
  /deep/ .van-uploader__preview {
    margin: 0;
  }

  /deep/ .van-uploader__preview-image {
    width: 100vw;
    height: auto;
    min-height: 100vw;
  }
}

.btn {
  padding: 20px;
}
</style>
